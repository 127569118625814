<template>
  <div class="creditDetailsBox">
    <!-- 授信详情 -->
    <!-- 申请信息 -->
    <div class="enterprise-information public-distance">
      <h4>
        申请信息 <template>
          <el-button v-if="$route.query.audit" type="primary" size="small" class="confirm-audit" @click="confirmAudit">
            审核
          </el-button>
        </template>
      </h4>
      <!-- 状态 -->
      <img
        v-if="detailObj.applicationResult === '333'"
        class="creditState"
        src="@/assets/image/daishengxiao.png"
        alt=""
        width="56"
        height="56"
      >
      <img
        v-else-if="detailObj.applicationResult === '334'"
        class="creditState"
        src="@/assets/image/yishengxiao.png"
        alt=""
        width="56"
        height="56"
      >
      <img
        v-else-if="detailObj.applicationResult === '335'"
        class="creditState"
        src="@/assets/image/yishixiao.png"
        alt=""
        width="56"
        height="56"
      >
      <el-descriptions :colon="false">
        <el-descriptions-item label="授信单号:">
          {{
            detailObj.creditNo
          }}
        </el-descriptions-item>
        <el-descriptions-item label="资金方:">
          {{
            detailObj.funderName
          }}
        </el-descriptions-item>
        <el-descriptions-item label="申请联系人:">
          {{
            detailObj.contract
          }}
        </el-descriptions-item>
        <el-descriptions-item label="联系方式:">
          {{
            detailObj.contractPhone
          }}
        </el-descriptions-item>
        <el-descriptions-item label="申请时间:">
          {{
            detailObj.createTime
          }}
        </el-descriptions-item>
        <el-descriptions-item label="申请状态:">
          <span v-if="detailObj.applicationResult">
            {{
              detailObj.applicationResult === '1000' ? '资金方已通过' : detailObj.applicationResult === '4000' ? '资金方处理中' : detailObj.applicationResult === '8000' ? '资金方已驳回' : ''
            }}
          </span>
          <span v-else-if="detailObj.auditResult==='-1'">平台已拒绝</span>
          <span v-else>平台审核中</span>
        </el-descriptions-item>
        <el-descriptions-item label="审核时间:">
          {{
            detailObj.auditTime
          }}
        </el-descriptions-item>
        <el-descriptions-item label="审核备注:" class-name="distance-bottom">
          {{
            detailObj.reviewRemarks
          }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <!--企业基本信息  -->
    <div class="license-information public-distance">
      <h4>企业基本信息</h4>
      <el-descriptions :colon="false">
        <el-descriptions-item label="申请单位:">
          {{ detailObj.creditCmpBaseInfo.cmpName }}
        </el-descriptions-item>
        <el-descriptions-item label="法人代表人:">
          {{
            detailObj.creditCmpBaseInfo.leadName
          }}
        </el-descriptions-item>
        <el-descriptions-item label="统一社会信用代码:">
          {{
            detailObj.creditCmpBaseInfo.cmpUnicode
          }}
        </el-descriptions-item>
        <el-descriptions-item label="营业执照:" class-name="distance-bottom">
          <img
            :src="$fileUrl + detailObj.creditCmpBaseInfo.businessLicense"
            alt=""
            width="152"
            height="96"
            :onerror="$errImage"
            @click="$refs.picturePresentation.dialogImageVisible=true;imgUrl=detailObj.creditCmpBaseInfo.businessLicense"
          >
        </el-descriptions-item>
        <el-descriptions-item label="法人身份证:" class-name="distance-bottom">
          <img
            :src="$fileUrl + detailObj.creditCmpBaseInfo.leadIdcardFront"
            alt=""
            width="152"
            height="96"
            :onerror="$errImage"
            @click="$refs.picturePresentation.dialogImageVisible=true;imgUrl=detailObj.creditCmpBaseInfo.leadIdcardFront"
          >
          <img
            :src="$fileUrl + detailObj.creditCmpBaseInfo.leadIdcardBack"
            alt=""
            width="152"
            height="96"
            class="the"
            :onerror="$errImage"
            @click="$refs.picturePresentation.dialogImageVisible=true;imgUrl=detailObj.creditCmpBaseInfo.leadIdcardBack"
          >
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <!-- 上传资料 -->
    <div class="uploadData public-distance">
      <h4>上传资料</h4>
      <el-table :data="detailObj.documentList" style="width: 100%">
        <el-table-column
          label="序号"
          prop="documentOrder"
          type="index"
          width="400"
        />
        <el-table-column prop="documentName" label="材料名称" />
        <el-table-column prop="address" label="操作">
          <template #default="{ row }">
            <div class="operation-left">
              <img
                v-if="row.type === 'pdf'"
                src="@/assets/img/fileType1.png"
                alt=""
              >
              <img
                v-else-if="row.type === 'doc' || row.type === 'docx'"
                src="@/assets/img/fileType3.png"
                alt=""
              >
              <img
                v-else-if="row.type === 'xlsx' || row.type === 'xls'"
                src="@/assets/img/fileType4.png"
                alt=""
              >
              <img
                v-else-if="row.type === 'zip'"
                src="@/assets/img/fileType2.png"
                alt=""
              >
              <img
                v-else-if="
                  row.type === 'png' ||
                    row.type === 'jpeg' ||
                    row.type === 'jpg'
                "
                src="@/assets/img/fileType5.png"
                alt=""
              >
              <span class="operation-name">
                {{ row.fileName }}
              </span>
              <el-button
                link
                type="primary"
                size="small"
                @click="downloadListFile(row)"
              >
                下载
              </el-button>
              <el-button
                v-if="
                  row.type === 'png' ||
                    row.type === 'jpeg' ||
                    row.type === 'jpg'||
                    row.type === 'pdf'
                "
                link
                type="primary"
                size="small"
                @click="checkFile($fileUrl + row.documentUrl)"
              >
                查看
              </el-button>
              <el-button v-else link type="primary" size="small" disabled>
                查看
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 授信方案 -->
    <div v-if="detailObj.applicationResult&&detailObj.applicationResult === '1000'" class="license-information public-distance">
      <h4>授信方案</h4>
      <!-- 状态 -->
      <img
        v-if="detailObj.creditStatus === '335'"
        class="creditState"
        src="@/assets/image/yishixiao.png"
        alt=""
        width="56"
        height="56"
      >
      <img
        v-else-if="detailObj.creditStatus === '334'"
        class="creditState"
        src="@/assets/image/yishengxiao.png"
        alt=""
        width="56"
        height="56"
      >
      <img
        v-else-if="detailObj?.creditStatus === '4000'"
        class="creditState"
        src="@/assets/image/chulizhong.png"
        alt=""
        width="56"
        height="56"
      >
      <img
        v-else-if="detailObj?.creditStatus === '8000'"
        class="creditState"
        src="@/assets/image/yibohui.png"
        alt=""
        width="56"
        height="56"
      >
      <img
        v-else-if="detailObj.creditStatus === '333'"
        class="creditState"
        src="@/assets/image/daishengxiao.png"
        alt=""
        width="56"
        height="56"
      >
      <el-descriptions :colon="false">
        <el-descriptions-item label="授信额度:">
          {{
            detailObj.scfCreditFinancePlanResp && detailObj.scfCreditFinancePlanResp.creditLine
          }}
        </el-descriptions-item>
        <el-descriptions-item
          label="融资比例:"
        >
          {{
            detailObj.scfCreditFinancePlanResp && detailObj.scfCreditFinancePlanResp.loanRate
          }}%
        </el-descriptions-item>
        <el-descriptions-item label="授信状态:">
          <el-tag v-if="detailObj.creditStatus === '333'" type="danger">
            待生效
          </el-tag>
          <el-tag v-if="detailObj.creditStatus === '334'" type="success">
            已生效
          </el-tag>
          <el-tag v-if="detailObj.creditStatus === '335'">
            已失效
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="授信开始日:">
          {{
            detailObj.scfCreditFinancePlanResp && detailObj.scfCreditFinancePlanResp.creditTimeStart
          }}
        </el-descriptions-item>
        <el-descriptions-item label="授信结束日:">
          {{
            detailObj.scfCreditFinancePlanResp && detailObj.scfCreditFinancePlanResp.creditTimeEnd
          }}
        </el-descriptions-item>
        <el-descriptions-item label="支付方式:">
          {{
            detailObj.scfCreditFinancePlanResp && detailObj.scfCreditFinancePlanResp.paymentType === 0
              ? "自主支付"
              : "定向支付"
          }}
        </el-descriptions-item>
        <el-descriptions-item label="贷款周期:">
          {{ detailObj.scfCreditFinancePlanResp && detailObj.scfCreditFinancePlanResp.maxLoanCycle }}
          <span
            v-if=" detailObj.scfCreditFinancePlanResp && detailObj.scfCreditFinancePlanResp.maxLoanCycleUnit === 1"
          >天</span>
          <span
            v-else-if="
              detailObj.scfCreditFinancePlanResp && detailObj.scfCreditFinancePlanResp.maxLoanCycleUnit === 2
            "
          >月</span>
          <span v-else>年</span>
        </el-descriptions-item>
        <el-descriptions-item label="贷款展期期限:">
          {{ detailObj.scfCreditFinancePlanResp && detailObj.scfCreditFinancePlanResp.loanExtensionPeriod }}

          <span
            v-if=" detailObj.scfCreditFinancePlanResp && detailObj.scfCreditFinancePlanResp.maxLoanCycleUnit === 1"
          >天</span>
          <span
            v-else-if="
              detailObj.scfCreditFinancePlanResp && detailObj.scfCreditFinancePlanResp.maxLoanCycleUnit === 2
            "
          >月</span>
          <span v-else>年</span>
        </el-descriptions-item>
        <el-descriptions-item label="还款方式:">
          <span
            v-if="detailObj.scfCreditFinancePlanResp && detailObj.scfCreditFinancePlanResp.repayType === 1"
          >等额本息</span>
          <span
            v-else-if="detailObj.scfCreditFinancePlanResp && detailObj.scfCreditFinancePlanResp.repayType === 2"
          >到期一次性还本付息</span>
          <span v-else>按月结息到期还本</span>
        </el-descriptions-item>
        <el-descriptions-item label="结息方式:">
          <span
            v-if="detailObj.scfCreditFinancePlanResp && detailObj.scfCreditFinancePlanResp.repayType === 1"
          >定日结息</span>
          <span
            v-else-if="detailObj.scfCreditFinancePlanResp && detailObj.scfCreditFinancePlanResp.repayType === 2"
          >贷放日结息</span>
          <span v-else>定日结息</span>
        </el-descriptions-item>
        <el-descriptions-item label="是否需要担保:">
          {{
            detailObj.scfCreditFinancePlanResp && detailObj.scfCreditFinancePlanResp.needGuarantee === 1
              ? "无担保"
              : "有担保"
          }}
        </el-descriptions-item>
        <el-descriptions-item label="担保方式:">
          <span
            v-if="detailObj.scfCreditFinancePlanResp && detailObj.scfCreditFinancePlanResp.guaranteeType === 1"
          >信用担保</span>
          <span
            v-else-if="detailObj.scfCreditFinancePlanResp && detailObj.scfCreditFinancePlanResp.guaranteeType === 2"
          >抵押担保</span>
          <span
            v-else-if="detailObj.scfCreditFinancePlanResp && detailObj.scfCreditFinancePlanResp.guaranteeType === 3"
          >质押担保</span>
          <span v-else>保证担保</span>
        </el-descriptions-item>
        <el-descriptions-item label="担保公司:">
          {{
            detailObj.scfCreditFinancePlanResp && detailObj.scfCreditFinancePlanResp.guaranteeName
          }}
        </el-descriptions-item>
        <el-descriptions-item label="担保协议:" class-name="distance-bottom">
          {{
            detailObj.scfCreditFinancePlanResp && detailObj.scfCreditFinancePlanResp.guarCompcatName
          }}
        </el-descriptions-item>
      </el-descriptions>
      <el-table
        :data="detailObj.scfCreditFinancePlanResp && detailObj.scfCreditFinancePlanResp.costs"
        style="width: 100%"
      >
        <el-table-column label="费用类型" prop="costName" />
        <el-table-column label="收费方式" prop="charging" width="280" />
        <el-table-column label="单笔最低收款（元）" prop="minCharging" />
        <el-table-column label="资方是否代扣" prop="funderWithhold" />
        <el-table-column label="收取方" prop="receiver" />
        <el-table-column label="支付方" prop="payer" />
      </el-table>
      <div class="agreement">
        <a
          v-for="(item, index) in (detailObj.scfCreditFinancePlanResp ? detailObj.scfCreditFinancePlanResp.agrees : [])"
          :key="index"
          target="_blank"
          :href="$fileUrl + item.compactUrl"
        >《{{ item.compactName }}》</a>
      </div>
    </div>
    <!-- 展示大图 -->
    <PicturePresentation ref="picturePresentation" :img-url="imgUrl" />
    <!-- 审核弹窗 -->
    <Dialog ref="dialog" :need-dialog-title="'审核'" dialog-width="40%" :form-item="formItem" @getFormData="getFormData" @changeFormSelect="changeFormSelect" />
  </div>
</template>

<script>
import { detailsCreditManagement, creditAudit } from '@/api/orderFinancing'
import { getResourceByTask } from '@/api/memberManagement'
import { downloadFile2 } from '@/utils/util'
import Dialog from '@/components/Dialog.vue'
import PicturePresentation from '@/components/PicturePresentation.vue'
export default {
  components: { Dialog, PicturePresentation },
  data() {
    return {
      imgUrl: '',
      detailObj: {
        creditCmpBaseInfo: {},
        documentList: [],
        scfCreditFinancePlanResp: {
          creditStatus: '',
          agrees: [],
          costs: []
        }
      },
      formItem: [
        {
          label: '审核状态',
          type: 'select',
          value: 'approveResult',
          optionLabel: 'VARIABLE_NAME',
          optionId: 'VARIABLE_CODE',
          child: []
        },
        {
          label: '原因',
          type: 'input',
          value: 'approveAdvise',
          rules: 'none'
        }
      ],
      resourceByTaskObj: {
        auditStatus: ''
      }
    }
  },
  mounted() {
    this.getdata()
    this.resourceByTaskObj = this.$store.state.resourceByTaskObj || JSON.parse(sessionStorage.getItem('resourceByTaskObj'))
  },
  methods: {
    // 授信详情
    getdata() {
      detailsCreditManagement(this.$route.query.id, res => {
        this.detailObj = res.data
        this.detailObj.documentList.forEach(item => {
          if (item.documentUrl) {
            item.type = item.documentUrl.split('.')[1]
          }
        })
        this.detailObj.scfCreditFinancePlanResp && this.detailObj.scfCreditFinancePlanResp.costs.forEach(item => {
          switch (item.receiver) {
            case 1:
              item.receiver = '资金方'
              break

            case 2:
              item.receiver = '担保方'
              break
            case 3:
              item.receiver = '平台方'
              break
          }

          switch (item.chargingRule) {
            case 2:
              item.charging = item.charging + '%/年'
              break

            case 3:
              item.charging = item.charging + '元/笔'
              break
            case 1:
              item.charging = item.charging + '%'
              break
          }
          // 资方是否代扣
          if (item.funderWithhold === 1) {
            item.funderWithhold = '是'
          } else {
            item.funderWithhold = '否'
          }
          // 支付方
          if (item.payer === 1) {
            item.payer = '购买方'
          } else {
            item.payer = '销售方'
          }
        })
      })
    },
    // 下载文件
    downloadListFile(row) {
      downloadFile2(this.$fileUrl + row.documentUrl, row.documentName, row.type)
    },
    // 查看文件
    checkFile(url) {
      window.open(url)
    },
    // 确认审核按钮
    confirmAudit() {
      this.$refs.dialog.editFormVisible = true
      getResourceByTask(this.resourceByTaskObj, res => {
        this.formItem.forEach(item => {
          if (item.value === 'approveResult') {
            item.child = [...res.data]
          }
        })
      })
    },
    // 审核提交
    getFormData(value) {
      const obj = {
        ...value,
        applyCode: this.detailObj.id,
        activiteId: this.resourceByTaskObj.activiteId,
        cmpState: this.resourceByTaskObj.auditStatus,
        currentNodeId: this.resourceByTaskObj.currentNodeId
      }
      creditAudit(obj, () => {
        this.$refs.dialog.editFormVisible = false
        this.$message.success('成功！')
        this.$router.go(-1)
      })
    },
    changeFormSelect(val) {
      if (val === 'approve_refuse') return delete this.formItem[1].rules
      if (val === 'approve_refuse') return this.formItem[1].rules
      this.$set(this.formItem[1], 'rules', 'none')
    }
  }
}
</script>
<style lang="scss">
.creditDetailsBox {
  // margin: 24px 0 24px 24px;

  .confirm-audit {
    width: 100px;
    position: absolute;
    right: 200px;
    top: 24px;
    z-index: 11;
  }
  .title-credit {
    font-size: 20px;
  }
  .steps {
    width: 100%;
    margin-top: 20px;
    padding: 10px 0;
    background-color: #fff;
    .globalStep .el-step__icon {
      height: 30px;
      width: 30px;
    }
    .globalStep .el-step__line {
      top: 16px !important;
    }
  }
  .public-distance {
    position: relative;
    width: calc(100% - 48px);
    margin-top: 16px;
    padding: 24px 24px 32px;
    background-color: #fff;
    .creditState{
      position: absolute;
      top: 36px;
      right: 36px;
    }
    .el-table--fit {
      margin-top: 20px;
    }
    .el-table tr th {
      background-color: #f7f7f7 !important;
      color: #000;
    }
  }
  // .public-distance:first-of-type{
  //   padding-top: 0;
  // }
  .tableBox {
    margin-top: 16px;
    padding: 20px 24px 45px;
    background-color: #fff;
    .agreement {
      a {
        color: #1363E8;
        margin-right: 12px;
      }
    }
    .title {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 28px;
    }
  }
  .agreement {
    margin: 16px 0px 0px;

    a {
      color: blue;
      font-size: 14px;
    }
  }
  .tableConponents {
    padding: 20px 30px 20px 0;
  }
  .el-descriptions__body {
    padding: 20px 32px 0 0;
    .el-descriptions__table:not(.is-bordered) .el-descriptions__cell {
      width: 20%;
      padding-bottom: 24px;
    }
    .el-descriptions__label.el-descriptions__cell:not(.is-bordered-label).is-vertical-label {
      padding-bottom: 4px;
    }
    .distance-bottom {
      padding-bottom: 0 !important;
    }
  }

  .the {
    margin-left: 6px;
  }
  .button {
    padding: 20px 20px 0;
  }
  .operation-left {
    width: 100%;
    display: flex;
    align-items: center;
    img {
      margin-right: 12px;
      height: 12px;
      width: 12px;
    }
    .operation-name {
      flex: 1;
    }
  }
  .link-botton {
    position: absolute !important;
    right: 60px !important;
  }
}
</style>
